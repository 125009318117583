@font-face {
font-family: '__suisseIntlFonts_aa1ccd';
src: url(/_next/static/media/0370663d4bd2e672-s.p.otf) format('opentype');
font-display: swap;
font-weight: 100;
font-style: italic;
}

@font-face {
font-family: '__suisseIntlFonts_aa1ccd';
src: url(/_next/static/media/10b591b2b2b58bc9-s.p.otf) format('opentype');
font-display: swap;
font-weight: 900;
font-style: normal;
}

@font-face {
font-family: '__suisseIntlFonts_aa1ccd';
src: url(/_next/static/media/4c7940212deeab04-s.p.otf) format('opentype');
font-display: swap;
font-weight: 900;
font-style: italic;
}

@font-face {
font-family: '__suisseIntlFonts_aa1ccd';
src: url(/_next/static/media/16a36e9b3e44fc25-s.p.otf) format('opentype');
font-display: swap;
font-weight: 700;
font-style: normal;
}

@font-face {
font-family: '__suisseIntlFonts_aa1ccd';
src: url(/_next/static/media/793b1dfe95af5e18-s.p.otf) format('opentype');
font-display: swap;
font-weight: 700;
font-style: italic;
}

@font-face {
font-family: '__suisseIntlFonts_aa1ccd';
src: url(/_next/static/media/0a6a5ab0b5191b30-s.p.otf) format('opentype');
font-display: swap;
font-weight: 400;
font-style: normal;
}

@font-face {
font-family: '__suisseIntlFonts_aa1ccd';
src: url(/_next/static/media/1fec8b82b5b23069-s.p.otf) format('opentype');
font-display: swap;
font-weight: 400;
font-style: italic;
}

@font-face {
font-family: '__suisseIntlFonts_aa1ccd';
src: url(/_next/static/media/993ce5bc940e5f95-s.p.otf) format('opentype');
font-display: swap;
font-weight: 300;
font-style: normal;
}

@font-face {
font-family: '__suisseIntlFonts_aa1ccd';
src: url(/_next/static/media/56bf4d47e702b6a6-s.p.otf) format('opentype');
font-display: swap;
font-weight: 300;
font-style: italic;
}

@font-face {
font-family: '__suisseIntlFonts_aa1ccd';
src: url(/_next/static/media/697ace9e2b6be03d-s.p.otf) format('opentype');
font-display: swap;
font-weight: 500;
font-style: normal;
}

@font-face {
font-family: '__suisseIntlFonts_aa1ccd';
src: url(/_next/static/media/36528e7bf6d6afbb-s.p.otf) format('opentype');
font-display: swap;
font-weight: 500;
font-style: italic;
}

@font-face {
font-family: '__suisseIntlFonts_aa1ccd';
src: url(/_next/static/media/e36691a40a84af7b-s.p.otf) format('opentype');
font-display: swap;
font-weight: 400;
font-style: normal;
}

@font-face {
font-family: '__suisseIntlFonts_aa1ccd';
src: url(/_next/static/media/af0a6a4a41f61be0-s.p.otf) format('opentype');
font-display: swap;
font-weight: 400;
font-style: italic;
}

@font-face {
font-family: '__suisseIntlFonts_aa1ccd';
src: url(/_next/static/media/18152d5a5b7acb7e-s.p.otf) format('opentype');
font-display: swap;
font-weight: 600;
font-style: normal;
}

@font-face {
font-family: '__suisseIntlFonts_aa1ccd';
src: url(/_next/static/media/1757b862aaeb611a-s.p.otf) format('opentype');
font-display: swap;
font-weight: 600;
font-style: italic;
}

@font-face {
font-family: '__suisseIntlFonts_aa1ccd';
src: url(/_next/static/media/1551b789c151d96d-s.p.otf) format('opentype');
font-display: swap;
font-weight: 100;
font-style: normal;
}

@font-face {
font-family: '__suisseIntlFonts_aa1ccd';
src: url(/_next/static/media/bdd4589108244eb9-s.p.otf) format('opentype');
font-display: swap;
font-weight: 200;
font-style: normal;
}

@font-face {
font-family: '__suisseIntlFonts_aa1ccd';
src: url(/_next/static/media/b340484f27a904e0-s.p.otf) format('opentype');
font-display: swap;
font-weight: 200;
font-style: italic;
}@font-face {font-family: '__suisseIntlFonts_Fallback_aa1ccd';src: local("Arial");ascent-override: 90.73%;descent-override: 18.92%;line-gap-override: 2.72%;size-adjust: 103.05%
}.__className_aa1ccd {font-family: '__suisseIntlFonts_aa1ccd', '__suisseIntlFonts_Fallback_aa1ccd', sans-serif
}

